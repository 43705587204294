.Loading-container{
    display: flex;
width: 100%;
height: 100vh;
/* padding: 0px 659px; */
justify-content: center;
align-items: center;
background: var(--pallate-blue-gray-800, #1E293B);
}
.loader{
    display: flex;
flex-direction: column;
align-items: center;
gap: 26px;
}
.loading-box{
    width: 602px;
    height: 20px;
}
.loading{
    width: 602px;
height: 20px;
flex-shrink: 0;
background: var(--pallate-blue-gray-700, #334155);
}
.loaded{
    width: 488px;
height: 20px;
flex-shrink: 0;
background: #9746FF;
}