.payment-container {
    height: 100vh;
    width: 100%;
    background: var(--pallate-blue-gray-900, #0F172A);
    display: flex;
    overflow: hidden;
}

.payment-info{
    display: flex;
    flex-direction: column;
    gap:10px;
    flex-grow: 1px;
    padding: 100px;
}

.payment-box{
    display: flex;
    flex-direction: column;
    padding:10px;
    border-radius: 20px;
    gap:10px;
    background-color:var(--pallate-blue-gray-800, #1E293B);
}

.user-tier{
    display: flex;
    flex-direction: column;
    gap:10px
}

.hrLine {
    color: var(--pallate-blue-gray-900, #0F172A);
    border: var(--pallate-blue-gray-900, #0F172A);
}

.plan_details{
    display: flex;
    gap:10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    
}

.paid_plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: #9746FF;
    width: 50%;
    padding: 20px;
}

.custom_plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: #9746FF;
    padding: 20px;
    width: 50%;
}

.button-container1 {
    display: inline-flex;
    height: 24px;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
    background: var(--pallate-blue-gray-500, #64748B);
    margin-left: 32px;
    margin-top: 37px;
}

.back-button1 {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 5.269px 4.25px 5.25px 4.25px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}
.back-button1:hover{
    cursor: pointer;
}
.arrow1 {
    width: 15.5px;
    height: 13.481px;
    flex-shrink: 0;
}