@media screen and (max-width: 1000px) {
    div.updatePasssword {
        background: linear-gradient(341deg, #00C2FF -39.75%, #9746FF 90.24%);
        display: flex;
        /* width: 360px; */
        padding: 262px 15px 29px 16px;
        flex-direction: column;
        /* justify-content: flex-end; */
        align-items: center;
        flex-direction: column;
    }

    div.updateptext{
        display: flex;
        width: 329px;
        min-height: 509px;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    div.updated{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    div.backlogin{
        display: flex;
        margin-top:100px;
        padding: 20px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }
}