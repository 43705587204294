.forgotPasssword{
    min-height: 100vh;
    height:100%;
    width: 100%;
    background: #1E293B;
    display: flex;
    flex-direction: row;
    align-items: center;
overflow: auto;
}
.forgotPasssword::-webkit-scrollbar{
    display: none;
}
body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
.Rectangleframe-forgotpassword{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    flex-shrink: 0;
    background: linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%);
}

@media screen and (max-width: 600px) {
    div.forgotPasssword {
        flex-direction: column;
    }

    div.Rectangleframe-forgotpassword, div.Rectangleframe-forgotpassword{
        width: 100vw;
        height: 60vh;
        padding: 0;
        /* background: linear-gradient(341deg, #00C2FF -39.75%, #9746FF 90.24%); */
    }

    .Rectangleframe-forgotpassword > div {
        gap: 12px !important;
    }

}
@media screen and (max-width: 900px)and (min-width:600px){
    .forgotPasssword{
        min-height: 100vh;
        height:100%;
        width: 100%;
        background: #1E293B;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
}
