.landingContainer {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
	background: var(--pallate-blue-gray-900, #0F172A);
}

.landingContainer::-webkit-scrollbar{
    display: none;
}

.header{
    display: flex;
    width: 1217px;
    justify-content: space-between;
    align-items: center;
    gap: 938px;
    background: transparent;
}

.insightFrame{
    display: inline-flex;
    align-items: center;
    gap: 30px;
    max-width: 1440px;
    width: 100%;
    justify-content: right;
    /* flex-wrap: wrap; */
}

.unFrame{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}
.get-startHover:hover{
scale: 1.03;
}
.AI-image{
    display: flex;
    max-width: 1217px;
width: 100%;
padding: 71px 0px 70px 62px;
align-items: center;
background: url(../assets/landing/AI-NoText.png), lightgray 50% / cover no-repeat;
background-repeat: no-repeat;
height: 609px;
}
.image-content{
    display: flex;
    width: 341px;
padding: 24px;
align-items: center;
gap: 8px;
background: rgba(1, 7, 63, 0.80);
border-radius: 48px;
}


.howit{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    overflow-x: hidden;
}
.why-dna{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
}

.works{
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.howitexp{
    display: flex;
    width: 1217px;
    flex-direction: column;
    align-items: flex-start;
    gap: 155px;
}

.create_exp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
}



.dashboardback{
    width: 600px;
    height: 337.5px;
    filter: drop-shadow(0px 0px 108px #000);
}

.dashboard{
    width: 600px;
    height: 337.5px;
    flex-shrink: 0;
    border: 8px solid var(--pallate-blue-gray-700, #334155);
    background: var(--pallate-blue-gray-800, #1E293B);
}

.upload_data{
    display: flex;
    width: 537px;
    max-width: 90vw;
    height: 212px;
    flex-direction: column;
    align-items: center;
    gap: 12px;


}
.image-shadow{
 box-shadow: 0px 0px 108px #000;   
}

.up_text_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.try_it_now{
    display: flex;
    width: 100%;
   
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 33px;
    background: linear-gradient(94deg, #9746FF 6.44%, #5F45FF 83.37%);
}

.try_box{
    display: flex;
     padding: 40px;
    justify-content: space-around;
    align-items: flex-start;
    align-self: stretch;
}




.try_text_box{
    display: flex;
    width: 819px;
    max-width: 90%;
    flex-direction: column;
    align-items: center;
    gap: 33px;
}

.D_A_viz{
    display: flex;
    width: 1216px;
    justify-content: center;
    align-items: center;
    gap: 65px;
}



.D_A_inside{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    
}

.analytics{
    display: flex;
    width: 581px;
    max-width: 90vw;
    /* height: 487px; */
    padding: 0px 0.315px 0.442px 0px;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
}

.D_A_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.D_A_content_1{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
}

.D_A_second_box{
    display: flex;
    align-items: flex-start;
    gap: 32px;

}

.D_A_third_box_colour{
    display: flex;
    width: 280px;
    height: 258px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(45, 212, 191, 0.25);
    
}

.D_A_third_box_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
}

.D_A_fourth_box_colour{
    display: flex;
    width: 280px;
    height: 258px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(129, 140, 248, 0.25);
    ;
    
}

.D_A_fourth_box_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
}

.D_A_second_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}


.D_A_first_box_colour{
    display: flex;
    width: 280px;
    height: 258px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(244, 114, 182, 0.25);
    
}

.D_A_first_box_colour .water{
    position: absolute;
    background: url('/src/assets/chat/wave1.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    margin-top: -20px;
    border-radius: 10px;
    background-position-y: 400px;
    animation: wateranimate 12s alternate-reverse infinite;
    transition: all .6s ease-in-out;
}

.D_A_first_box_colour:hover .water{
    background-position-y: -100px;
}
.D_A_second_box_colour .water1{
    position: absolute;
    background: url('/src/assets/chat/wave2.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    margin-top: -20px;
    border-radius: 10px;
    background-position-y: 400px;
    animation: wateranimate 12s alternate-reverse infinite;
    transition: all .6s ease-in-out;
}
.D_A_third_box_colour .water2{
    position: absolute;
    background: url('/src/assets/chat/wave3.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    margin-top: -20px;
    border-radius: 10px;
    background-position-y: 400px;
    animation: wateranimate 12s alternate-reverse infinite;
    transition: all .6s ease-in-out;
}.D_A_fourth_box_colour .water3{
    position: absolute;
    background: url('/src/assets/chat/wave4.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    margin-top: -20px;
    border-radius: 10px;
    background-position-y: 400px;
    animation: wateranimate 12s alternate-reverse infinite;
    transition: all .6s ease-in-out;
}
.D_A_second_box_colour:hover .water1{
    background-position-y: -100px;
}.D_A_third_box_colour:hover .water2{
    background-position-y: -100px;
}.D_A_fourth_box_colour:hover .water3{
    background-position-y: -100px;
}

@keyframes wateranimate{
    to{
        background-position-x: 0;
    }
    from{
        background-position-x: -900px;
    }
}

.D_A_first_box_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
}

.D_A_second_box_colour{
    display: flex;
    width: 280px;
    height: 258px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: rgba(251, 146, 60, 0.25);
    ;
    
}

.D_A_second_box_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    
}

.samples_done{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
}

.sample_done_head{
    display: flex;
    width: 684px;
    max-width: 90vw;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: rgba(255, 102, 102, 0.25);
}

.sample_content{
    display: flex;
    width: 1217px;
    justify-content: space-between;
    align-items: flex-start;
    /* overflow-x: auto;
    overflow-y: hidden; */
    max-width: 100vw;
}
.sample_content::-webkit-scrollbar{
    display: none;
}



.pie_sample{
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--pallate-blue-gray-800, #1E293B);
    /* -webkit-box-reflect: below 2px linear-gradient(to bottom, rgba(0, 0, 0.2, 0), rgba(0, 0.2, 0.2, .2)); */
    transition: ease-in-out 0.3s;
    align-self: stretch;
}

.pie_sample:hover{
    scale: 1.1;
  
}


.pie_header{
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.pie_heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.pie_img{
    display: flex;
    height: 240px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.pie_footer{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    align-self: stretch;
}

.pie_footer_inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.pie_footer_first{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.pie_footer_second{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.pie_color_one{
    display: flex;
    align-items: center;
    gap: 10px;
}

.pie_text_one{
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.bar_sample{
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--pallate-blue-gray-800, #1E293B);
    /* -webkit-box-reflect: below 2px linear-gradient(to bottom, rgba(0, 0, 0.2, 0), rgba(0, 0.2, 0.2, .2)); */
    transition: ease-in-out 0.3s;
    align-self: stretch;
}
.bar_sample:hover{
    scale: 1.1;
  
}

.bar_header{
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.bar_heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.line_sample{
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--pallate-blue-gray-800, #1E293B);
    /* -webkit-box-reflect: below 2px linear-gradient(to bottom, rgba(0, 0, 0.2, 0), rgba(0, 0.2, 0.2, .2)); */
    transition: ease-in-out 0.3s;
    align-self: stretch;
}
.line_sample:hover{
    scale: 1.1;
  
}
.line_header{
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.line_heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.stacked_bar_sample{
    display: flex;
    width: 280px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--pallate-blue-gray-800, #1E293B);
    /* -webkit-box-reflect: below 2px linear-gradient(to bottom, rgba(0, 0, 0.2, 0), rgba(0, 0.2, 0.2, .2)); */
    transition: ease-in-out 0.3s;
    align-self: stretch;
}
.stacked_bar_sample:hover{
    scale: 1.1;
  
}

.stacked_bar_header{
    display: flex;
    padding: 20px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.stacked_bar_heading{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
}

.bar_img{
    display: flex;
    height: 240px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.line_img{
    display: flex;
    height: 240px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.stacked_bar_img{
    display: flex;
    height: 240px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.bar_footer{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    height:80px;
    align-self: stretch;
}

.bar_inner{
    display: flex;
    justify-content: center;
    width:100%;
    align-items: flex-start;
    flex: 1 0 0;
}

.bar_frame{
    display: flex;
    width: 180px;
    justify-content: space-between;
    align-items: flex-start;
    gap:20px;
}

.bar_color_one{
    display: flex;
    align-items: center;
    gap: 10px;
}

.stacked_bar_footer{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    align-self: stretch;
}

.test_main_frame{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
    }

.test_head{
    display: flex;
    width: 684px;
    max-width: 90vw;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: rgba(34, 197, 94, 0.25);
}

.testimonial_frame{
    display: flex;
    max-width: 1216px;
    width: 100vw;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 65px;
    margin-right: 20px;
}

.testimonial_inner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
}

.testimonial_main{
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}



.testimonial_one{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    background: rgba(69, 90, 100, 0.50);
}

.testimonial_one_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.testimonial_footer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: 4px;
    align-self: stretch;
}

.contact_frame{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    align-self: stretch;
}

.contact_title{
    display: flex;
    width: 384px;
    max-width: calc(90vw - 16px);
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 24px;
    background: rgba(151, 70, 255, 0.25);
}

.contact_main{
    display: flex;
    width: 991px;
    max-width: 90vw;
    justify-content: space-between;
    align-items: center;
}

.send_message_img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.send_img{
    display: flex;
    width: 305px;
    height: 282px;
    padding: 0px 0.034px 0.294px 0px;
    justify-content: center;
    align-items: center;
}

.contact_details_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}

.name_box{
    display: flex;
    width: 622px;
    max-width: 90vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
}

.message_box{
    display: flex;
    height: 128px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
}



.footer {
    background: #1F2937;
    padding: 43px 0;
    display: inline-block;
    width: 100vw;
    margin-top: 113px;
    display: flex;
    gap:16px;
    flex-direction: column;
    align-items: center;
}

.footer_container {
    display: flex;
    max-width: 90%;
    width: 100%;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
}

.aiq_logo {
    width: 101px;
height: 58px;
}

.footer_copyright {
    color: white;
    width: 100%;
    text-align: left;
    font-family: Inter !important;
    font-size: 14px !important;
}

.footer_divider {
    display: none;
}
.form-container{
    display: flex;
flex-direction: column;
align-items: center;
gap: 32px;
align-self: stretch;
}
@media screen and (max-width: 1200px) {
    .sample_content {
        gap: 24px;
        padding: 0 20px;
        box-sizing: border-box;
        overflow-x: auto;
        margin-bottom: 0;
    }
    .sample_content::-webkit-scrollbar{
        display: none;
    }
    .testimonial_inner::-webkit-scrollbar{
        display: none;
    }
    .pie_sample:hover{
        scale: 1;
    }
    .bar_sample:hover{
        scale: 1;
    }
    .line_sample:hover{
        scale: 1;
    }
    .stacked_bar_sample:hover{
        scale: 1;
    }
    div.testimonial_main {
        padding: 0 20px;
        gap: 24px;
    }
    .footer_copyright {
        color: white;
        width: 100%;
        text-align: left;
        font-family: Inter !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 750px) {
    /* .create_exp img {
        width: 92vw !important;
    } */

    .howit, .samples_done {
        gap: 30px;
    }
    .footer_copyright {
        color: white;
        text-align: center;
        font-family: Inter !important;
        font-size: 14px !important;
    }
    .aiq_logo {
        width: 74px;
height: 42px;
    }
    .footer_viz_logo{
        width: 97px;
height: 42px;
    }
    .footer_container {
        display: flex;
        max-width: 80%;
        width: 100%;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
    }
    .why-dna{
        width:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
    }
    .form-container{
        display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    }
    div.testimonial_main {
        padding: 0 20px;
        gap: 24px;
    }
}

@media screen and (max-width: 1250px) {

    /* .landingContainer::-webkit-scrollbar{
        display: none;
    } */

    .header {
        justify-content: start;
        width: 100%;
    }

    .header img {
        padding-left: 8px;
    }

    .create_exp {
        flex-direction: column;
        gap: 40px;
        padding: 0px 20px;
    }

    .create_exp:nth-child(even) {
        flex-direction: column-reverse;
    }

    .howitexp {
        align-items: center;
        width: 100%;
        gap: 0;
    }
}
@media screen and (max-width: 600px) {
    .unFrame {
        gap: 20px;
    }

    .insightFrame {
        gap: 40px;
    }
}
@media screen and (max-width: 1400px) {
    .try_box {
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 1450px) {
    .D_A_viz {
        width: 100%;
        flex-direction: column;
        align-self: center;
    }
}

@media screen and (max-width: 700px) {
    .D_A_second_box {
        flex-direction: column;
        gap: 24px;
    }

    .D_A_content_1 {
        gap: 24px;
    }

    .samples_done, .test_main_frame {
        gap: 30px !important;
    }

    .contact_frame {
        gap: 30px;
    }
    .sample_content{
        display: flex;
        width: 1217px;
        justify-content: space-between;
        align-items: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100vw;
        margin-bottom: 0;
    }
    /* .sample_content > div {
        width: calc(100vw - 60px);
    } */
    .sample_content::-webkit-scrollbar{
        display: none;
    }
    .pie_sample:hover{
        scale: 1;
    }
    .bar_sample:hover{
        scale: 1;
    }
    .line_sample:hover{
        scale: 1;
    }
    .stacked_bar_sample:hover{
        scale: 1;
    }
    div.testimonial_one {
        min-width: calc(100vw - 60px);
        box-sizing: border-box;
    }

    .footer_divider {
        display: block;
        width: 1px;
        height: 42.066px;
        background: #475569;
    }

    /* div.test_head, div.testimonial_frame {
        display: block;
    } */

    div.contact_frame {
        gap: 30px;
    }
}
@media screen and (max-width: 1100px) {
    .testimonial_one {
        min-width: 300px;
    }

    .contact_main {
        flex-direction: column;
        gap: 64px;
    }
}
@media screen and (max-width: 1000px) {
    .insightFrame {
        flex-direction: column;
    }

    .insightFrame img {
        height: unset !important;
    }
}